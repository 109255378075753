(function ($) {
  'use strict';
  $(function () {

    let iconSearch = $('.header__search');
    let search = $('.search');
    let menu = $('.cat');

    iconSearch.on('click', function () {
      search.removeClass('hide');
      menu.addClass('hide');
      iconSearch.addClass('hide');

      $(document).mouseup(function (e)
      {

        if (!search.is(e.target) && search.has(e.target).length === 0) {
          search.addClass('hide');
          menu.removeClass('hide');
          iconSearch.removeClass('hide');
        }
      });
    });

  });
})(jQuery);
