import "@fancyapps/fancybox";
//import "@fortawesome/fontawesome-free/js/all.js";
import "./foundation.js";
//import "./fontLoad.js";
import "./carousels.js";
import "./handleInputNumber.js";
import "./stickyFooter.js";
import "./search.js";
import "./jquery.maskedinput.min.js";
import "./phone.js";

global.$ = $;

import "../scss/style.scss";
