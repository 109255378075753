import "slick-carousel";
(function ($) {
  "use strict";
  $(function () {
    $(".carousel-main").slick({
      infinite: true,
      dots: true,
      arrows: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 5000,
      autoplayHoverPause: true,
      fade: true,
      swipeToSlide: true,
      prevArrow: '<svg class="slick-prev" width="29" height="62" viewBox="0 0 29 62" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
        '<g filter="url(#filter0_d)">\n' +
        '<path d="M24 5L6 31L24 57" stroke="white" stroke-opacity="0.5" stroke-width="2"/>\n' +
        '</g>\n' +
        '<defs>\n' +
        '<filter id="filter0_d" x="0.783741" y="0.430786" width="28.0385" height="61.1384" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">\n' +
        '<feFlood flood-opacity="0" result="BackgroundImageFix"/>\n' +
        '<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>\n' +
        '<feOffset/>\n' +
        '<feGaussianBlur stdDeviation="2"/>\n' +
        '<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"/>\n' +
        '<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>\n' +
        '<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>\n' +
        '</filter>\n' +
        '</defs>\n' +
        '</svg>\n',
      nextArrow: '<svg class="slick-next" width="29" height="62" viewBox="0 0 29 62" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
        '<g filter="url(#filter0_d)">\n' +
        '<path d="M5 5L23 31L5 57" stroke="white" stroke-opacity="0.5" stroke-width="2"/>\n' +
        '</g>\n' +
        '<defs>\n' +
        '<filter id="filter0_d" x="0.177856" y="0.430786" width="28.0385" height="61.1384" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">\n' +
        '<feFlood flood-opacity="0" result="BackgroundImageFix"/>\n' +
        '<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>\n' +
        '<feOffset/>\n' +
        '<feGaussianBlur stdDeviation="2"/>\n' +
        '<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"/>\n' +
        '<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>\n' +
        '<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>\n' +
        '</filter>\n' +
        '</defs>\n' +
        '</svg>\n'
    });

    $(".carousel-clients").slick({
      infinite: true,
      dots: false,
      arrows: true,
      slidesToShow: 6,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 5000,
      autoplayHoverPause: true,
      swipeToSlide: true,
      prevArrow: '<svg class="slick-prev slick-prev_clients" width="21" height="54" viewBox="0 0 21 54" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
        '<path d="M20 1L2 27L20 53" stroke="#CBD9E4" stroke-width="2"/>\n' +
        '</svg>',
      nextArrow: '<svg class="slick-next slick-next_clients" width="21" height="54" viewBox="0 0 21 54" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
        '<path d="M1 1L19 27L1 53" stroke="#CBD9E4" stroke-width="2"/>\n' +
        '</svg>\n',
      responsive: [
        {
          breakpoint: 960,
          settings: {
            slidesToShow: 3
          }
        },
        {
          breakpoint: 1440,
          settings: {
            slidesToShow: 4
          }
        },
      ]
    });

    $(".staff__carousel").slick({
      infinite: true,
      dots: false,
      arrows: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 5000,
      autoplayHoverPause: true,
      swipeToSlide: true,
      prevArrow: '<svg class="slick-prev slick-prev_clients slick-prev_staff" width="21" height="54" viewBox="0 0 21 54" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
        '<path d="M20 1L2 27L20 53" stroke="#CBD9E4" stroke-width="2"/>\n' +
        '</svg>',
      nextArrow: '<svg class="slick-next slick-next_clients slick-next_staff" width="21" height="54" viewBox="0 0 21 54" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
        '<path d="M1 1L19 27L1 53" stroke="#CBD9E4" stroke-width="2"/>\n' +
        '</svg>\n',
      responsive: [
        {
          breakpoint: 800,
          settings: {
            slidesToShow: 1,
            dots: true
          }
        },
        {
          breakpoint: 960,
          settings: {
            slidesToShow: 2,
            dots: true
          }
        },
      ]
    });

    $(".reviews__carousel").slick({
      infinite: true,
      dots: false,
      arrows: true,
      slidesToShow: 2,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 5000,
      autoplayHoverPause: true,
      swipeToSlide: true,
      prevArrow: '<svg class="slick-prev slick-prev_clients slick-prev_reviews" width="21" height="54" viewBox="0 0 21 54" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
        '<path d="M20 1L2 27L20 53" stroke="#CBD9E4" stroke-width="2"/>\n' +
        '</svg>',
      nextArrow: '<svg class="slick-next slick-next_clients slick-next_reviews" width="21" height="54" viewBox="0 0 21 54" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
        '<path d="M1 1L19 27L1 53" stroke="#CBD9E4" stroke-width="2"/>\n' +
        '</svg>\n',
      responsive: [
        {
          breakpoint: 960,
          settings: {
            slidesToShow: 1,
            dots: true
          }
        },
      ]
    });

    $(".accreditation__slider").slick({
      infinite: true,
      dots: false,
      arrows: true,
      slidesToShow: 5,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 5000,
      autoplayHoverPause: true,
      prevArrow: '<svg class="slick-prev slick-prev_clients" width="21" height="54" viewBox="0 0 21 54" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
        '<path d="M20 1L2 27L20 53" stroke="#CBD9E4" stroke-width="2"/>\n' +
        '</svg>',
      nextArrow: '<svg class="slick-next slick-next_clients" width="21" height="54" viewBox="0 0 21 54" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
        '<path d="M1 1L19 27L1 53" stroke="#CBD9E4" stroke-width="2"/>\n' +
        '</svg>\n',
      responsive: [
        {
          breakpoint: 640,
          settings: {
            slidesToShow: 1,
            dots: true
          }
        },
        {
          breakpoint: 960,
          settings: {
            slidesToShow: 3,
            dots: true,
            arrows: false
          }
        },
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 4,
            dots: true,
            arrows: false
          }
        },
        {
          breakpoint: 1440,
          settings: {
            slidesToShow: 5,
            dots: true,
            arrows: false
          }
        },
      ]
    });

    $(".letters__slider").slick({
      infinite: true,
      dots: false,
      arrows: true,
      slidesToShow: 5,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 5000,
      autoplayHoverPause: true,
      prevArrow: '<svg class="slick-prev slick-prev_clients" width="21" height="54" viewBox="0 0 21 54" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
        '<path d="M20 1L2 27L20 53" stroke="#CBD9E4" stroke-width="2"/>\n' +
        '</svg>',
      nextArrow: '<svg class="slick-next slick-next_clients" width="21" height="54" viewBox="0 0 21 54" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
        '<path d="M1 1L19 27L1 53" stroke="#CBD9E4" stroke-width="2"/>\n' +
        '</svg>\n',
      responsive: [
        {
          breakpoint: 640,
          settings: {
            slidesToShow: 1,
            dots: true
          }
        },
        {
          breakpoint: 800,
          settings: {
            slidesToShow: 2,
            dots: true,
            arrows: false
          }
        },
        {
          breakpoint: 960,
          settings: {
            slidesToShow: 3,
            dots: true,
            arrows: false
          }
        },
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 4,
            dots: true,
            arrows: false
          }
        },
        {
          breakpoint: 1440,
          settings: {
            slidesToShow: 4,
            dots: true,
            arrows: false
          }
        },
      ]
    });

    $(".documents__slider").slick({
      infinite: true,
      dots: false,
      arrows: true,
      slidesToShow: 4,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 5000,
      autoplayHoverPause: true,
      prevArrow: '<svg class="slick-prev slick-prev_clients" width="21" height="54" viewBox="0 0 21 54" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
        '<path d="M20 1L2 27L20 53" stroke="#CBD9E4" stroke-width="2"/>\n' +
        '</svg>',
      nextArrow: '<svg class="slick-next slick-next_clients" width="21" height="54" viewBox="0 0 21 54" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
        '<path d="M1 1L19 27L1 53" stroke="#CBD9E4" stroke-width="2"/>\n' +
        '</svg>\n',
      responsive: [
        {
          breakpoint: 640,
          settings: {
            slidesToShow: 1,
            dots: true
          }
        },
        {
          breakpoint: 800,
          settings: {
            slidesToShow: 2,
            dots: true,
            arrows: false
          }
        },
        {
          breakpoint: 960,
          settings: {
            slidesToShow: 3,
            dots: true,
            arrows: false
          }
        },
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 4,
            dots: true,
            arrows: false
          }
        },
      ]
    });

  });
})(jQuery);
